import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { ref, onMounted, computed } from 'vue';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import { uploadCloudHelpFileUrl, selectCloudHelpFileUrl } from '@/api/help';
import { useStore } from 'vuex';
export default {
  components: {
    uploadImgs
  },

  setup() {
    const fileUrl = ref([]); //获取附件的url

    const getAdjuncts = val => {
      console.log('getAdjuncts', val);
      fileUrl.value = val;
      setUrlData(val);
    }; //获取文件名称数据


    const getUrlData = async () => {
      const res = await selectCloudHelpFileUrl();

      if (res.code === 200) {
        // console.log(res.data.fileUrl)
        fileUrl.value = JSON.parse(res.data.fileUrl); // console.log(fileUrl.value)
      } else {
        _message.error(res.message);
      }
    }; //上传文件名称数据


    const setUrlData = async val => {
      const res = await uploadCloudHelpFileUrl({
        fileUrl: JSON.stringify(val)
      });

      if (res.code === 200) {// console.log(res.message)
      } else {
        _message.error(res.message);
      }
    };

    const store = useStore();
    const isCompanyAdmin = computed(() => store.state.user.isCompanyAdmin);
    onMounted(() => {
      getUrlData();
    });
    return {
      getAdjuncts,
      fileUrl,
      isCompanyAdmin
    };
  }

};